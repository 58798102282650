import {
  Button,
  Container,
  FileInput,
  Flex,
  LoadingOverlay,
  Select,
  Stack,
  TextInput,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { IconFileCv } from "@tabler/icons-react";
import axios from "axios";
import { useState } from "react";
import { statesProvince } from "../../constants";
import uploadFileToS3 from "../../services/fileUploader";
import { API_URL } from "../../services/url";

type Props = {
  // Prop types for children components
  onNext?: () => void;
};

const DriverLicenseDetails = (Props: Props) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [licenseNumber, setLicenseNumber] = useState("");
  const [driver_dc_no, setDriverDCNo] = useState("");

  const [expirationDate, setExpirationDate] = useState<Date | null>();
  const [issuingState, setIssuingState] = useState<string | null>();

  const [driverCertification, setDriverCertification] = useState<File>();
  const [dcPhoto, setDriverDCPhoto] = useState<File>();

  const [wheelchairAccessibility, setWheelchairAccessibility] = useState<
    string | null
  >("");
  const [loading, setLoading] = useState(false);

  const isFormValid =
    licenseNumber && expirationDate && issuingState && wheelchairAccessibility;

  const handleSubmit = async () => {
    if (!isFormValid) {
      showNotification({
        title: "Validation Error",
        message: "Please fill in all the required fields.",
        color: "red",
      });
      return;
    }

    setLoading(true);

    try {
      const userId = localStorage.getItem("userId");
      if (userId === null || userId === undefined) {
        window.location.href = "/signin";
        return;
      }

      let driverCertificationUrl: string = "";
      if (driverCertification !== null && driverCertification !== undefined) {
        driverCertificationUrl = await uploadFileToS3(
          driverCertification,
          "outss-assets",
          `driver_certifications/${userId}/${driverCertification.name}`
        );
      }

      let driver_dc_photo: string = "";
      if (dcPhoto !== null && dcPhoto !== undefined) {
        driver_dc_photo = await uploadFileToS3(
          dcPhoto,
          "outss-assets",
          `dc_certifications/${userId}/${dcPhoto.name}`
        );
      }

      const response = await axios.post(`${API_URL}driver-license/info/`, {
        driver_license_number: licenseNumber,
        license_expiration_date: expirationDate.toISOString().split("T")[0],
        license_issuing_state: issuingState,
        driving_license_photo: driverCertificationUrl,
        wheelchair_accessibility: wheelchairAccessibility,
        driver: userId,
        driver_dc_photo,
        driver_dc_no,
      });
      // Handle successful response
      console.log(response.data);
      showNotification({
        title: "Success",
        message: "Driver license details updated successfully.",
        color: "green",
      });
      // window.location.href = '/vehicle';
      if (Props?.onNext) Props.onNext();
    } catch (error) {
      // Handle error
      console.error(error);
      showNotification({
        title: "Error",
        message: "An error occurred while updating driver license details.",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        // height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "self-start",
        textAlign: "left",
      }}
    >
      <LoadingOverlay visible={loading} />
      <h2>Driver License Details</h2>
      <Stack style={{ width: "100%" }}>
        <TextInput
          label="Driver License Number"
          placeholder="Enter your driver license number"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={licenseNumber}
          onChange={(event) => setLicenseNumber(event.currentTarget.value)}
        />

        <TextInput
          label="Driver DC Number"
          placeholder="Enter your driver dc number"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={driver_dc_no}
          onChange={(event) => setDriverDCNo(event.currentTarget.value)}
        />

        <FileInput
          leftSection={
            <IconFileCv
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          }
          mb="sm"
          onChange={(files) => files && setDriverDCPhoto(files)}
          size="md"
          label="Upload DC"
          placeholder="Your DC"
          leftSectionPointerEvents="none"
        />

        <DatePickerInput
          mb="sm"
          size="md"
          required
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={expirationDate}
          minDate={new Date()}
          onChange={setExpirationDate}
          label="License Expiration Date"
        />

        <Select
          label="License Issuing State/Province"
          placeholder="Select the issuing state/province"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          data={statesProvince}
          value={issuingState}
          onChange={(value) => setIssuingState(value)}
        />

        <Select
          label="Wheelchair Accessibility"
          placeholder="Select wheelchair accessibility"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          data={[
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ]}
          value={wheelchairAccessibility}
          onChange={setWheelchairAccessibility}
        />

        <FileInput
          leftSection={
            <IconFileCv
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          }
          mb="sm"
          onChange={(files) => files && setDriverCertification(files)}
          size="md"
          label="Upload license"
          placeholder="Your License"
          leftSectionPointerEvents="none"
        />

        <Flex justify={"center"}>
          <Button
            w={"90%"}
            size="md"
            c={"fcolor"}
            variant="gradient"
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            onClick={handleSubmit}
            radius={"md"}
            mt={"sm"}
            disabled={!isFormValid || loading}
            loading={loading}
            loaderProps={{ type: "oval" }}
          >
            Next
          </Button>
        </Flex>
      </Stack>
    </Container>
  );
};

export default DriverLicenseDetails;
