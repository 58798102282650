import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Modal,
  Overlay,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconMap, IconPlayerTrackNext, IconX } from "@tabler/icons-react";
import axios from "axios";
import { memo, useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import uploadFileToS3 from "../../services/fileUploader";
import { API_URL } from "../../services/url";
import { humenReadableTime } from "../../utils/datetime";
import { TripProps } from "../CreateJob/type";
import { CameraAction } from "./cameraaction";

type Props = {
  jobId: string | null;
  tripinfo: TripProps | null | undefined;
  onRefetch: () => void;
};

export const TripDetails: React.FC<Props> = memo(
  ({ jobId, tripinfo, onRefetch }) => {
    const userId = localStorage.getItem("userId");

    const [loading, setLoading] = useState(false);

    const [uploading, setUploading] = useState(false);

    const [opened, { open, close }] = useDisclosure(false);

    const [tripInfo, setTripInfo] = useState<TripProps | null>();

    const [jobImage, setJobimage] = useState<File>();

    const [imgopen, setImgopen] = useState(false);
    
    const {
      coords,
      getPosition,
      isGeolocationAvailable,
      isGeolocationEnabled,
      positionError,
    } = useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
      watchLocationPermissionChange: true,
    });

    // useEffect(() => {
    //   if (isGeolocationAvailable && isGeolocationEnabled)
    //     setTimeout(() => {
    //       alert(getPosition());
    //     }, 1000);
    // }, [isGeolocationAvailable, isGeolocationEnabled]);

    const getLocation = () => {
      if (isGeolocationEnabled && isGeolocationAvailable) {
        // alert("getLocation" + JSON.stringify(coords));
        return {
          latitude: coords?.latitude,
          longitude: coords?.longitude,
        };
      }
      // return new Promise<{ latitude: number; longitude: number }>(
      //   (resolve, reject) => {
      //     try {
      //       if (navigator.geolocation) {
      //         navigator.geolocation.getCurrentPosition(
      //           (position) => {
      //             resolve({
      //               latitude: position.coords.latitude,
      //               longitude: position.coords.longitude,
      //             });
      //           },
      //           (error) => {
      //             reject(error);
      //           }
      //         );
      //       } else {
      //         reject(new Error("Geolocation is not supported by this browser."));
      //       }
      //     } catch (error) {
      //       reject(new Error("Error"+error));
      //     }
      //   }
      // );
    };

    const createGoogleMapsUrl = (latLongString: string) => {
      const [latitude, longitude] = latLongString.split(",");
      return `https://www.google.com/maps?q=${latitude},${longitude}`;
    };

    const MapRedirector = ({ latLongString }: { latLongString: string }) => {
      const handleRedirect = () => {
        const url = createGoogleMapsUrl(latLongString);
        window.location.href = url;
      };

      return (
        <div>
          <button
            onClick={handleRedirect}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid",
              borderRadius: "5px",
            }}
          >
            <IconMap size={20} style={{ marginRight: "5px" }} />
            View on Map
          </button>
        </div>
      );
    };

    useEffect(() => {
      if (tripinfo) setTripInfo(tripinfo);
    }, [tripinfo]);

    const handleStartTrip = async () => {
      getPosition();
      const loc = getLocation();
      // alert("From Handle Start Trip" + JSON.stringify(loc));
      // alert("From Handle Start Trip" + JSON.stringify(positionError));
      if (loc) {
        try {
          setLoading(true);

          const res = await axios.post(`${API_URL}start-trip/`, {
            job_id: jobId,
            driver_id: userId,
            start_location: `${loc.latitude},${loc.longitude}`,
            start_time: new Date().toISOString(),
          });

          console.log("🚀 ~ handleStartTrip ~ res:", res.data);

          if (res.data && res.data.id) {
            setLoading(false);
            showNotification({
              title: "Success",
              message: "Trip started successfully",
              color: "green",
            });
            onRefetch();
          }
        } catch (error) {
          setLoading(false);
          showNotification({
            title: "Error",
            message: "Failed to start trip",
            color: "red",
          });
        }
      } else
        showNotification({
          title: "Error",
          message: "Failed to get location",
          color: "red",
        });
    };

    const handleComplete = () => {
      const loc = getLocation();
      if (loc && tripInfo) {
        setTripInfo({
          ...tripInfo,
          amount_paid: 0,
          distance_driven: 0,
          end_location: `${loc.latitude},${loc.longitude}`,
          end_time: new Date().toISOString(),
        });
        open();
      } else
        showNotification({
          title: "Error",
          message: "Failed to get location or trip not found",
          color: "red",
        });
    };

    const handleSaveTrip = async () => {
      if (!tripInfo) return;

      try {
        setLoading(true);

        let invoiceurl: string = "";
        if (jobImage !== null && jobImage !== undefined) {
          invoiceurl = await uploadFileToS3(
            jobImage,
            "outss-assets",
            `job_images/${userId}/${jobImage.name}`
          );
        }

        const endTripData = {
          trip_id: tripInfo?.id,
          end_location: tripInfo?.end_location,
          end_time: tripInfo?.end_time,
          distance_driven: tripInfo?.distance_driven,
          amount_paid: tripInfo?.amount_paid,
          invoiceurl,
        };

        const res = await axios.post(`${API_URL}end-trip/`, endTripData);

        if (res.data && res.data.id) {
          setLoading(false);
          showNotification({
            title: "Success",
            message: "Trip completed successfully",
            color: "green",
          });
          onRefetch();
          close();
        }
      } catch (error) {
        setLoading(false);
        showNotification({
          title: "Error",
          message: "Failed to complete trip",
          color: "red",
        });
      }
    };

    const handleAmount = (amount: string) =>
      tripInfo && setTripInfo({ ...tripInfo, amount_paid: parseInt(amount) });

    const handleDistance = (distance: string) =>
      tripInfo &&
      setTripInfo({ ...tripInfo, distance_driven: parseInt(distance) });

    const alertModal = () => {
      modals.openConfirmModal({
        title: "Start Trip ?",
        centered: true,
        children: <Text size="sm">{"Are you sure want to start trip?"}</Text>,
        labels: { confirm: "Start Trip", cancel: "No don't start trip" },
        confirmProps: { color: "primary" },
        onCancel: () => console.log("Cancel"),
        onConfirm: () => handleStartTrip(),
      });
    };

    const updateJobImage = async (file: File) => {
      try {
        if (!file) return;
        setUploading(true);
        const invoice_url = await uploadFileToS3(
          file,
          "outss-assets",
          `job_images/${userId}/${file.name}`
        );

        const res = await axios.post(`${API_URL}update-trip-invoice/`, {
          trip_id: tripInfo?.id,
          invoice_url,
        });
        if (res) {
          if (!tripInfo) return;
          setTripInfo({ ...tripInfo, invoiceurl: invoice_url });
          showNotification({
            title: "Success",
            message: "Invoice uploaded successfully",
            color: "green",
          });
        }
      } catch (error) {
        showNotification({
          title: "Error",
          message: "Failed to upload invoice",
          color: "red",
        });
      } finally {
        setUploading(false);
      }
    };

    const isFormValid = tripInfo?.amount_paid && tripInfo?.distance_driven;

    console.log("🚀 ~ tripInfo:", tripInfo);

    const closeInvoice = () => {
      setImgopen(false);
    }

    if (tripInfo === null || tripInfo === undefined)
      return (
        <Flex justify={"center"}>
          <Button
            w={"90%"}
            size="md"
            variant="gradient"
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            radius={"md"}
            disabled={loading}
            rightSection={<IconPlayerTrackNext size={18} />}
            onClick={alertModal}
            loading={loading}
            loaderProps={{ type: "oval" }}
          >
            Start Trip
          </Button>
        </Flex>
      );

    if (tripInfo?.status !== "completed" && tripInfo?.status !== "started")
      return (
        <Text size="xs" fw={700} ta={"center"}>
          {tripInfo?.status}
        </Text>
      );

    if (tripInfo?.status === "completed")
      return (
        <Box>
          <Text size="lg" fw={700}>
            {`Trip Details`}
          </Text>
          <Stack
            gap="xl"
            my={"lg"}
            w={"100%"}
            style={{ border: "0.5px solid #091f5b", borderRadius: "10px" }}
            p={"md"}
          >
            <Flex justify={"space-between"} align={"center"}>
              <Box>
                <Text size="sm" fw={700}>{`Start location`}</Text>

                {tripInfo.start_location && (
                  <MapRedirector latLongString={tripInfo.start_location} />
                )}
              </Box>
              <Box>
                <Text size="sm" fw={700}>{`Start time`}</Text>
                <Text size="sm" fw={500}>
                  {humenReadableTime(tripInfo.start_time)}
                </Text>
              </Box>
            </Flex>

            <Flex justify={"space-between"} align={"center"}>
              <Box>
                <Text size="xs" fw={700}>{`End location`}</Text>

                {tripInfo.end_location && (
                  <MapRedirector latLongString={tripInfo.end_location} />
                )}
              </Box>
              <Box>
                <Text size="xs" fw={700}>{`End time`}</Text>
                <Text size="sm" fw={500}>
                  {humenReadableTime(tripInfo.end_time)}
                </Text>
              </Box>
            </Flex>

            <Flex justify={"space-between"} align={"center"} direction={"row"}>
              {tripInfo.invoiceurl !== "" ? (
                <Avatar src={tripInfo.invoiceurl} alt="it's me" size={"xl"} onClick={()=> setImgopen(true)} />
              ) : (
                <CameraAction
                  onCapture={(file) => {
                    setJobimage(file);
                    updateJobImage(file);
                  }}
                  uploadingImage={uploading}
                />
              )}
              <Modal opened={imgopen} centered onClose={() => setImgopen(false)} title="Invoice" >
                <Flex h={'100%'} align={'center'} direction={'column'} style={{overflow:'auto'}} justify={'center'} >
                  <Image src={tripInfo.invoiceurl} alt="it's me"/>
                </Flex>
              </Modal>

                {/* {imgopen && <Overlay color="#000" blur={2}>
                  <Flex pos={'fixed'} h={'100vh'} align={'center'} direction={'column'} style={{overflow:'auto'}} w={'100%'} justify={'center'} >
                    <Flex bg={'white'} direction={'column'} pb={'sm'}>
                      <Flex w={'100%'} justify={'space-between'} align={'center'} p={'sm'}>
                        <Title order={3}>Invoice</Title>
                        <IconX onClick={closeInvoice} />
                      </Flex>
                      <Image src={tripInfo.invoiceurl} px={'xs'}  alt="it's me"/>
                    </Flex>
                  </Flex>
                </Overlay> } */}

              <Flex
                justify={"space-around"}
                align={"flex-end"}
                direction={"column"}
              >
                <Flex columnGap={"xs"}>
                  <Text size="md" fw={500}>{`Job Completed by : `}</Text>
                  <Text size="md" fw={800}>
                    {` ${tripInfo.driver_name}`}
                  </Text>
                </Flex>

                <Flex columnGap={"xs"}>
                  <Text size="lg" fw={500}>{`Distance driven : `}</Text>
                  <Text size="lg" fw={800}>
                    {` ${tripInfo.distance_driven} km`}
                  </Text>
                </Flex>
                <Flex columnGap={"xs"}>
                  <Text size="lg" fw={500}>{`Amount : `}</Text>
                  <Text size="lg" fw={800}>
                    {`$ ${tripInfo.amount_paid}`}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Text ta={"center"} c={"green"} fw={700}>
              Trip Completed
            </Text>
          </Stack>
        </Box>
      );

    return (
      <Box>
        <Modal opened={opened} centered onClose={close} title="Complete Trip">
          <Stack gap="md" my={"lg"} w={"100%"}>
            {/* <FileInput
              leftSection={
                <IconPhoto
                  style={{ width: rem(18), height: rem(18) }}
                  stroke={1.5}
                />
              }
              mb="sm"
              onChange={(files) => files && setJobimage(files)}
              size="md"
              label="Upload Job Image"
              placeholder="Your Job Image"
              leftSectionPointerEvents="none"
            /> */}
            <CameraAction
              showTitle
              onCapture={setJobimage}
              uploadingImage={uploading}
            />

            <TextInput
              label="Amount Collected"
              placeholder="Ex : $ 1200"
              required
              type="number"
              size="sm"
              labelProps={{ style: { alignSelf: "flex-start" } }}
              value={tripInfo?.amount_paid}
              onChange={(event) => handleAmount(event.currentTarget.value)}
            />
            <TextInput
              label="Distance Travelled"
              placeholder="Ex : $ 1200"
              required
              type="number"
              size="sm"
              labelProps={{ style: { alignSelf: "flex-start" } }}
              value={tripInfo?.distance_driven}
              onChange={(event) => handleDistance(event.currentTarget.value)}
            />

            <Flex justify={"center"}>
              <Button
                w={"80%"}
                variant="gradient"
                gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
                radius={"md"}
                color="fcolor"
                disabled={!isFormValid || loading}
                rightSection={<IconPlayerTrackNext size={18} />}
                onClick={handleSaveTrip}
                loading={loading}
                // style={{ height: "50px" }}
                loaderProps={{ type: "oval" }}
              >
                Save Trip
              </Button>
            </Flex>
          </Stack>
        </Modal>

        <Text size="lg" fw={700}>
          {`Trip Details`}
        </Text>

        <Stack gap="md" my={"lg"} w={"100%"}>
          {tripInfo?.start_location && (
            <Flex align={"center"} justify={"flex-start"} columnGap={"md"}>
              <Text size="sm" fw={700}>
                {`Started from : `}
              </Text>

              {tripInfo.start_location && (
                <MapRedirector latLongString={tripInfo.start_location} />
              )}
            </Flex>
          )}

          {tripInfo?.start_time && (
            <Flex align={"center"} justify={"flex-start"} columnGap={"md"}>
              <Text size="sm" fw={700}>
                {`Started on : `}
              </Text>
              <Text size="sm" fw={400}>
                {tripInfo.start_time}
              </Text>
            </Flex>
          )}

          {tripInfo?.status === "started" && (
            <Flex justify={"center"}>
              <Button
                w={"80%"}
                variant="gradient"
                gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
                radius={"md"}
                disabled={loading}
                rightSection={<IconCheck size={18} />}
                onClick={handleComplete}
                loading={loading}
                // style={{ height: "50px" }}
                loaderProps={{ type: "oval" }}
              >
                Complete Trip
              </Button>
            </Flex>
          )}
        </Stack>
      </Box>
    );
  }
);
