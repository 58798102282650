import {
  Button,
  Container,
  Flex,
  LoadingOverlay,
  Menu,
  Select,
  Stack,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import "@mantine/dates/styles.css";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { useState } from "react";
import { API_URL } from "../../services/url";

type Props = {
  // Prop types for children components
  onNext?: () => void;
};

const PersonalDetails = (Props: Props) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [value, setValue] = useState<Date | null>(null);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState<string | null>("");

  const isFormValid = firstName && lastName && value && gender;

  const handleSubmit = async () => {
    if (!isFormValid) {
      showNotification({
        title: "Validation Error",
        message: "Please fill in all the required fields.",
        color: "red",
      });
      return;
    }

    setLoading(true);

    try {
      const userId = localStorage.getItem("userId");
      if (userId === null || userId === undefined) {
        window.location.href = "/signin";
        return;
      }
      const response = await axios.put(`${API_URL}driver/${userId}/`, {
        first_name: firstName,
        last_name: lastName,
        dob: value.toISOString().split("T")[0],
        gender: gender,
      });

      // Handle successful response
      console.log(response.data);
      showNotification({
        title: "Success",
        message: "Personal details updated successfully.",
        color: "green",
      });

      if (Props?.onNext) Props.onNext();

      // window.location.href = '/address';
    } catch (error) {
      // Handle error
      console.error(error);
      showNotification({
        title: "Error",
        message: "An error occurred while updating personal details.",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        // height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "self-start",
        textAlign: "left",
      }}
    >
      <LoadingOverlay visible={loading} />

      <h2>Personal Details</h2>
      <Stack style={{ width: "100%" }}>
        <TextInput
          label="First Name"
          placeholder="Enter your first name"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          value={firstName}
          onChange={(event) => setFirstName(event.currentTarget.value)}
        />

        <TextInput
          label="Last Name"
          placeholder="Enter your last name"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          styles={{
            label: { textAlign: "left" },
          }}
          value={lastName}
          onChange={(event) => setLastName(event.currentTarget.value)}
        />

        <Menu
          opened={opened}
          onChange={setOpened}
          trigger="click"
          position="bottom"
          withArrow
        >
          <Menu.Target>
            <TextInput
              label="Date of Birth"
              placeholder="Select your date of birth"
              required
              mb="sm"
              size="md"
              labelProps={{ style: { alignSelf: "flex-start" } }}
              onClick={() => setOpened(true)}
              value={value ? value.toLocaleDateString() : ""}
              readOnly
            />
          </Menu.Target>
          <Menu.Dropdown>
            <DatePicker
              allowDeselect
              maxDate={new Date()}
              value={value}
              onChange={(val) => {
                setValue(val);
                setOpened(false);
              }}
            />
          </Menu.Dropdown>
        </Menu>

        <Select
          label="Gender"
          placeholder="Select your gender"
          required
          mb="sm"
          size="md"
          labelProps={{ style: { alignSelf: "flex-start" } }}
          data={[
            { value: "Male", label: "Male" },
            { value: "Female", label: "Female" },
            { value: "Other", label: "Other" },
          ]}
          value={gender}
          onChange={(value) => setGender(value)}
        />
        <Flex justify={"center"}>
          <Button
            w={"90%"}
            size="md"
            color="fcolor"
            variant="gradient"
            gradient={{ from: "#2B2B2B", to: "#404040", deg: 180 }}
            mt={"sm"}
            disabled={!isFormValid || loading}
            onClick={handleSubmit}
            loading={loading}
            loaderProps={{ type: "oval" }}
            radius={"md"}
          >
            Next
          </Button>
        </Flex>
      </Stack>
    </Container>
  );
};

export default PersonalDetails;
