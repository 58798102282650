import {
  ActionIcon,
  Button,
  Container,
  Flex,
  LoadingOverlay,
  Stack,
  Text,
  Title,
  rem,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import {
  IconArrowLeft,
  IconCalendar,
  IconCar,
  IconCheck,
  IconClock,
  IconEdit,
  IconMapPin,
  IconWheelchair,
  IconX,
} from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import { API_URL } from "../../services/url";
import checkUserId from "../../utils/checkuserid";
import { humenReadableTime } from "../../utils/datetime";
import { JobProps, TripProps } from "../CreateJob/type";
import { AcceptOrDeclineJob } from "./acceptordeclinejob";
import { MakePublicAndInviteUser } from "./makepublicAndinviteuser";
import { ReportJob } from "./reportjob";
import { RequestedList } from "./requestedList";
import { ResetJob } from "./resetjob";
import { ReturnJob } from "./returnjob";
import { TripDetails } from "./tripdetails";

const JobInfo = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const jobId = urlParams.get("id");

  const userId = localStorage.getItem("userId");

  const isMobile = useMediaQuery("(max-width: 600px)");

  const [jobInfo, setJobInfo] = useState<JobProps | null>(null);

  const [tripInfo, setTripInfo] = useState<TripProps | null>();

  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    checkUserId()
  }, []);

  const fetchJob = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}job-details/${jobId}`);
      console.log(response);
      if (response.data) setJobInfo(response.data);
      else
        showNotification({
          title: "Error",
          message: "Failed to fetch job details",
          color: "red",
        });
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to fetch job details",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchTripDetails = async () => {
    try {
      const res = await axios.get(`${API_URL}trip-details/?job_id=${jobId}`);
      if (res.data.length === 0) return;
      else setTripInfo(res.data[0]);
      console.log("🚀 ~ fetchTripDetails ~ res:", res.data[0]);
    } catch (error) {
      console.log("🚀 ~ fetchTripDetails ~ error:", error);
    }
  };

  useEffect(() => {
    fetchJob();
    fetchTripDetails();
  }, []);

  // handlecancel
  const handleUpdateJob = async (job: JobProps) => {
    try {
      setLoading(true);
      const cancelres = await axios.get(
        `${API_URL}jobs/${jobId}/cancel/${jobInfo?.posted_by.id}/`
      );
      console.log("🚀 ~ handleUpdateJob ~ cancelres:", cancelres);
      if (cancelres.data.success) {
        if (jobInfo) setJobInfo(job);
      } else
        showNotification({
          title: "Error",
          message: "Failed to cancel job",
          color: "red",
        });
    } catch (error) {
      console.log("🚀 ~ handleUpdateJob ~ error:", error);
      showNotification({
        title: "Error",
        message: "Failed to cancel job",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  //Cancelmodal
  const cancelmodal = () =>
    modals.openConfirmModal({
      title: "Are you sure want to cancel your job?",
      centered: true,
      // children: <Text size="sm">Are you sure want to Cancel your job?</Text>,
      labels: {
        confirm: "Yes, Cancel",
        cancel: "No",
      },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        handleCancel();
      },
      confirmProps: { color: "red" },
    });

  const handleCancel = () => {
    if (jobInfo)
      handleUpdateJob({
        ...jobInfo,
        status: "cancelled",
        public: false,
        assigned_to: null,
        // posted_by:jobInfo.posted_by.id
      });
  };

  const style = { width: rem(16), height: rem(16) };

  const LocationIcon = <IconMapPin style={style} />;

  const CalendarIcon = <IconCalendar style={style} />;

  const CarIcon = <IconCar style={style} />;

  const WheelChairIcon = <IconWheelchair style={style} color="blue" />;

  const CheckIcon = <IconCheck style={style} color="green" />;

  const CloseIcon = <IconX style={style} color="red" />;

  const PendingIcon = <IconClock style={style} />;

  const redirectHome = () => (window.location.href = "/home");

  const canShowJob = (job: JobProps) => {
    if (job.posted_by.id === userId) {
      if (job.status === "pending" || job.status === "completed") {
        return true;
      }
    }

    if (job.assigned_to === userId) {
      if (job.status === "assigned" || job.status === "completed") {
        return true;
      }
    }

    return false;
  };

  function HeaderCom() {
    return (
      <Flex justify={"space-between"} align={"center"} w={"100%"}>
        <IconArrowLeft
          style={{ width: rem(30), height: rem(30) }}
          onClick={redirectHome}
        />
        <Title order={2} ta={"center"} w={"100%"} fw={700}>
          {`Kabbie`}
        </Title>

        <Flex gap={"lg"}>
          {jobInfo?.posted_by.id === userId &&
            jobInfo.status !== "uncovered" &&
            jobInfo.status !== "completed" && (
              <ResetJob jobId={jobId} onRefetch={fetchJob} jobInfo={jobInfo} />
            )}
          <ReportJob jobId={jobId} />
        </Flex>
      </Flex>
    );
  }

  if (loading) return <LoadingOverlay visible={loading} />;

  if (!loading && !jobInfo)
    return (
      <Flex w={"100vw"} h={"100vh"} justify={"center"} align={"center"}>
        <Text>{`Job details not found`}</Text>{" "}
      </Flex>
    );

  if (!jobInfo)
    return (
      <Container
        size={isMobile ? "xs" : "sm"}
        px="lg"
        py={isMobile ? "md" : "xl"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "self-start",
          textAlign: "left",
        }}
        bg={"bg"}
      >
        <HeaderCom />

        <Text>{`Job details not found`}</Text>
      </Container>
    );

  const isAssignedOrCreator =
    jobInfo.assigned_to === userId || jobInfo.posted_by.id === userId;

  return (
    <Container
      size={isMobile ? "xs" : "sm"}
      px="lg"
      py={isMobile ? "md" : "xl"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "self-start",
        textAlign: "left",
      }}
      bg={"bg"}
    >
      <HeaderCom />

      <Flex
        direction={"column"}
        justify={"space-between"}
        w={"100%"}
        // mih={"80vh"}
      >
        <Stack
          my={"xs"}
          w={"100%"}
          style={{ border: "0.5px solid #091f5b", borderRadius: "10px" }}
          p={"lg"}
        >
          
            <Flex justify={"space-between"} align={"flex-start"}>
                  <Text size="lg" fw={800}>
                    {jobInfo.booking_id}
                  </Text>
     
              {isAssignedOrCreator && (
              <Text size="xs" fw={500} ta={"right"}>
                {humenReadableTime(jobInfo.posted_at)}
                <br />
                {(jobInfo.posted_by.id === userId || jobInfo.assigned_to === userId)
                  && `No : ${jobInfo.posted_by.vehicle.license_plate_number}`
                }
              </Text>
            )}
            </Flex>
          

          <Stack gap={0}>
            <Text c={"hcolor"} fw={400} size={"xs"}>{`Pickup Location`}</Text>
            <Flex align={"center"} gap={"xs"}>
              {LocationIcon}
              <Text
                size={"md"}
                fw={600}
              >{` ${jobInfo.pickup_address}, ${jobInfo.pickupLoc}`}</Text>
            </Flex>
          </Stack>
          <Stack gap={0}>
            <Text c={"hcolor"} size={"xs"} fw={400}>{`Drop Location`}</Text>
            <Flex align={"center"} gap={"xs"}>
              {LocationIcon}
              <Text size={"md"} fw={600}>
                {` ${jobInfo.dropLoc}`}
              </Text>
            </Flex>
          </Stack>
          <Stack gap={0}>
            <Text c={"hcolor"} size={"xs"} fw={400}>{`Pikcup Date`}</Text>
            <Flex align={"center"} gap={"xs"}>
              {CalendarIcon}
              <Text size={"md"} fw={600}>
                {` ${new Date(jobInfo.pickup_at).toLocaleDateString()}`}
              </Text>
            </Flex>
          </Stack>
          <Stack gap={0}>
            <Text c={"hcolor"} size={"xs"} fw={400}>{`Pikcup Time`}</Text>
            <Flex align={"center"} gap={"xs"}>
              {CalendarIcon}
              <Text size={"md"} fw={600}>
                {` ${new Date(jobInfo.pickup_at).toLocaleTimeString("en-US", {
                  hour12: true,
                  hour: "numeric",
                  minute: "numeric",
                })}`}
              </Text>
            </Flex>
          </Stack>
          <Stack gap={0}>
            <Text c={"hcolor"} size={"xs"} fw={400}>{`Service Type`}</Text>
            <Flex align={"center"} gap={"xs"}>
              {jobInfo.services_required.includes("wheel")
                ? WheelChairIcon
                : CarIcon}
              <Text
                size={"sm"}
                fw={600}
                style={{
                  color: jobInfo.services_required.includes("wheel")
                    ? "blue"
                    : "black",
                }}
              >
                {jobInfo.services_required}
              </Text>
            </Flex>
          </Stack>
          {isAssignedOrCreator && (
            <Flex justify={"space-between"} align={"flex-end"}>
              <Stack gap={0}>
                <Text size={"xs"} fw={400}>{`Status`}</Text>
                <Flex align={"center"} gap={"xs"}>
                  {jobInfo.status === "completed"
                    ? CheckIcon
                    : jobInfo.status === "cancelled"
                    ? CloseIcon
                    : PendingIcon}

                  <Text size={"sm"} fw={600} tt={"capitalize"}>
                    {jobInfo.status}
                  </Text>
                </Flex>
              </Stack>
              {jobInfo.posted_by.id === userId &&
              jobInfo.status !== "completed" ? (
                <ActionIcon
                  variant="filled"
                  aria-label="Settings"
                  size={"md"}
                  radius={"xl"}
                  color="primary"
                  onClick={() =>
                    (window.location.href = "/createjob?jobId=" + jobId)
                  }
                >
                  <IconEdit
                    style={{ width: "70%", height: "70%" }}
                    stroke={3}
                  />
                </ActionIcon>
              ) : null}
            </Flex>
          )}
        </Stack>

        {jobInfo.status === "cancelled" && (
          <Title
            order={3}
            mt={"sm"}
            ta={"center"}
            c={"red"}
          >{`Job Cancelled`}</Title>
        )}

        {/* {jobInfo.posted_by !== userId && (
          <RequestJob jobId={jobId} jobInfo={jobInfo} />
        )} */}

        {jobInfo.posted_by.id !== userId &&
          jobInfo.status !== "cancelled" &&
          jobInfo.status !== "completed" &&
          jobInfo.status !== "uncovered" &&
          jobInfo.status !== 'assigned' && (
            <AcceptOrDeclineJob
              jobId={jobId}
              jobInfo={jobInfo}
              onRefetch={fetchJob}
            />
          )}

        {jobInfo.posted_by.id === userId &&
          jobInfo.status !== "cancelled" &&
          jobInfo.status !== "completed" &&
          jobInfo.status !== "uncovered" && (
            <RequestedList
              jobId={jobId}
              jobInfo={jobInfo}
              onRefetch={fetchJob}
            />
          )}
        {jobInfo.status === "assigned" && (
          <Flex justify={"center"}>
            <Text>{`Job Assigned to : ${jobInfo.assigned_driver_name}`}</Text>
          </Flex>
        )}

        {canShowJob(jobInfo) && (
          <TripDetails
            jobId={jobId}
            onRefetch={fetchTripDetails}
            tripinfo={tripInfo}
          />
        )}

        {jobInfo.posted_by.id === userId &&
          !tripInfo &&
          jobInfo.status !== "cancelled" &&
          jobInfo.status !== "completed" &&
          jobInfo.status !== "uncovered" &&
          jobInfo.status !== "assigned" && (
            <MakePublicAndInviteUser
              jobId={jobId}
              jobInfo={jobInfo}
              tripInfo={tripInfo}
              onRefetch={fetchJob}
            />
          )}

        {jobInfo.assigned_to === userId &&
          !tripInfo &&
          jobInfo.status !== "cancelled" &&
          jobInfo.status !== "completed" &&
          jobInfo.status !== "uncovered" && (
            <ReturnJob jobId={jobId} onRefetch={fetchJob} />
          )}

        {jobInfo.posted_by.id === userId &&
          jobInfo.status !== "cancelled" &&
          jobInfo.status !== "completed" &&
          jobInfo.status !== "uncovered" &&
          !tripInfo && (
            <Flex justify={"center"}>
              <Button
                w={"90%"}
                size="md"
                radius={"md"}
                color="red"
                variant="outline"
                mt={"lg"}
                disabled={loading}
                onClick={cancelmodal}
                loaderProps={{ type: "oval" }}
              >
                Cancel
              </Button>
            </Flex>
          )}

        {jobInfo.posted_by.id === userId &&
          jobInfo.status === "uncovered" &&
          !tripInfo && (
            <ResetJob jobId={jobId} onRefetch={fetchJob} jobInfo={jobInfo} />
          )}
      </Flex>
    </Container>
  );
};

export default JobInfo;
